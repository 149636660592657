// WebSocketContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { HOST, PORT } from "../pages/config/config";

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children, user }) => {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        if (!user) return
        // Connect to the WebSocket server when the component mounts
        const ws = new WebSocket(`ws://${HOST}:${PORT}/socket?uuid=${user.uuid}`);

        // Handle connection events
        ws.onopen = () => console.log("WebSocket connected");
        ws.onclose = () => console.log("WebSocket disconnected");
        ws.onerror = (error) => console.error("WebSocket error:", error);

        // Set the WebSocket instance in state
        setSocket(ws);

        // Clean up the connection when the component unmounts
        return () => ws.close();
    }, []);

    return (
        <WebSocketContext.Provider value={socket}>
            {children}
        </WebSocketContext.Provider>
    );
};

// Custom hook to use the WebSocket context
export const useWebSocket = () => {
    return useContext(WebSocketContext);
};
