import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./UserPage.css";
import Post from "../posts/Post";
import { AuthContext } from "../../context/authContext";
import Newpost from "../posts/Newpost";
import GroupInfo from "../groups/GroupInfo";
import UserInfo from "./UserInfo";
import API_URL from "../config/config";
import ChatInfo from "../chat/ChatInfo";

export default function UserPage({ user: currentUser }) {
    const { updateUser } = useContext(AuthContext);
    const params = useParams();
    const username = params.username ? params.username : currentUser.username;
    const [usr, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState("photos");

    const [photos, setPhotos] = useState([]);
    const [videos, setVideos] = useState([]);
    const [publications, setPublications] = useState([]);
    const [friends, setFriends] = useState([]);
    const [groups, setGroups] = useState([]);
    const [chats, setChats] = useState([]);

    const fetchUser = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/users/find_with_username/${username}`, {
                withCredentials: true
            });
            setUser(response.data.user);
        } catch (err) {
            setError("User not found.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUser();
    }, [username]);

    const fetchPhotos = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/users/${username}/photos`, {
                withCredentials: true,
            });
            setPhotos(response.data.items);
        } catch (error) {
            console.error("Error fetching photos:", error);
        }
    };

    const fetchVideos = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/users/${username}/videos`, {
                withCredentials: true,
            });
            setVideos(response.data.items);
        } catch (error) {
            console.error("Error fetching videos:", error);
        }
    };

    const fetchPublications = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/users/${username}/publications`, {
                withCredentials: true,
            });
            setPublications(response.data.items);
        } catch (error) {
            console.error("Error fetching publications:", error);
        }
    };

    const fetchFriends = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/users/${username}/friends`, {
                withCredentials: true,
            });
            setFriends(response.data.items);
        } catch (error) {
            console.error("Error fetching friends:", error);
        }
    };

    const fetchGroups = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/users/${username}/groups`, {
                withCredentials: true,
            });
            setGroups(response.data.items);
        } catch (error) {
            console.error("Error fetching groups:", error);
        }
    };

    const fetchChats = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/users/${username}/chats`, {
                withCredentials: true,
            });
            setChats(response.data.items);
        } catch (error) {
            console.error("Error fetching chats:", error);
        }
    };

    const handleAddFriend = async () => {
        try {
            await axios.post(`${API_URL}/api/friends/send_request`, {
                to: usr.uuid // Assuming you have the user's UUID
            }, { withCredentials: true });
            updateUser({
                sent_friend_requests: [...currentUser.sent_friend_requests, usr.uuid],
            });
        } catch (err) {
            console.error(err);
            alert("Error sending friend request.");
        }
    };

    const handleCancelRequest = async () => {
        try {
            await axios.post(`${API_URL}/api/friends/remove_request`, {
                to: usr.uuid // Assuming you have the user's UUID
            }, { withCredentials: true });
            updateUser({
                sent_friend_requests: currentUser.sent_friend_requests.filter(id => id !== usr.uuid),
            });
        } catch (err) {
            console.error(err);
            alert("Error sending friend request.");
        }
    }

    const handleDeleteFriend = async () => {
        try {
            await axios.post(`${API_URL}/api/friends/remove_friend`, {
                to: usr.uuid // Assuming you have the user's UUID
            }, { withCredentials: true });
            updateUser({
                friends: currentUser.friends.filter(friend => friend !== usr.uuid),
            });
        } catch (err) {
            console.error(err);
            alert("Error sending friend request.");
        }
    }

    const handleAcceptRequest = async () => {
        try {
            await axios.post(`${API_URL}/api/friends/approve_request`, {
                to: usr.uuid // Assuming you have the user's UUID
            }, { withCredentials: true });
            updateUser({
                friends: [...currentUser.friends, usr.uuid],
            });
        } catch (err) {
            console.error(err);
            alert("Error sending friend request.");
        }
    }

    useEffect(() => {
        if (activeTab === "photos") fetchPhotos();
        if (activeTab === "videos") fetchVideos();
        if (activeTab === "publications") fetchPublications();
        if (activeTab === "friends") fetchFriends();
        if (activeTab === "groups") fetchGroups();
        if (activeTab === "chats") fetchChats();
    }, [activeTab, username]);

    if (loading) return <div>Loading...</div>;
    // console.log(error)
    if (!usr) return <div>Չի գտնուել</div>;

    return (
        <div className="user-page">
            <div className="user-header">
                <img
                    src={`https://realschool.am/db/get?id=${usr.photo}`}
                    alt={`${usr.username}'s avatar`}
                    className="user-avatar"
                />
                <h2>{usr?.name?.hye_AM || usr?.name || ""} {usr?.last_name || ""}</h2>
                <p>@{usr.username}</p>
            </div>
            <div className="user-details">
                <p><strong>Email:</strong> {usr.email}</p>
                <p><strong>Bio:</strong> {usr.bio || "No bio available"}</p>
                <p><strong>Age:</strong> {usr.age || "Unknown"}</p>
            </div>

            {currentUser.uuid !== usr.uuid && (
                <>
                    {currentUser.sent_friend_requests.includes(usr.uuid) ? (
                        <button className="add-friend-btn" onClick={handleCancelRequest}>Չեղարկել յայտը</button>
                    ) : currentUser.friends.includes(usr.uuid) ? (
                        <button className="add-friend-btn" onClick={handleDeleteFriend}>Հեռացնել ընկերներից</button>
                    ) : currentUser.get_friend_requests.includes(usr.uuid ) ? (
                        <button className="add-friend-btn" onClick={handleAcceptRequest}>Ընդունել յայտը</button>
                    ) : (
                        <button className="add-friend-btn" onClick={handleAddFriend}>Ավելացնել ընկեր</button>
                    )}
                </>
            )}

            <div className="tabs">
                <button onClick={() => setActiveTab("photos")} className={activeTab === "photos" ? "active" : ""}>Նկարներ</button>
                <button onClick={() => setActiveTab("videos")} className={activeTab === "videos" ? "active" : ""}>Տեսանիւթեր</button>
                <button onClick={() => setActiveTab("publications")} className={activeTab === "publications" ? "active" : ""}>Յայտարարութիւններ</button>
                <button onClick={() => setActiveTab("friends")} className={activeTab === "friends" ? "active" : ""}>Ընկերներ</button>
                <button onClick={() => setActiveTab("groups")} className={activeTab === "groups" ? "active" : ""}>{currentUser.uuid !== usr.uuid ? "Համատեղ խմբեր" : "Խմբեր" }</button>
            </div>
            <div className="tabs">
                <button onClick={() => setActiveTab("chats")} className={activeTab === "chats" ? "active" : ""}>{currentUser.uuid !== usr.uuid ? "Համատեղ զրուցարաններ" : "Զրուցարաններ" }</button>

            </div>

            <div className="tab-content">
                {activeTab === "photos" && (
                        photos.length > 0 ? (
                            photos.map(photo => (
                            <img key={photo} src={`https://realschool.am/db/get?id=${photo.photo}`} alt="User photo" className="media-item" />
                            ))
                        ) : (
                            <p>Նկարներ չկան։</p>
                        )
                    )
                }

                {activeTab === "videos" && (
                    videos.length > 0 ? (
                        videos.map(video => (
                            <video
                                key={video}
                                controls
                                src={`https://realschool.am/db/get?id=${video.video}`}
                                className="media-item"
                            />
                        ))
                    ) : (
                        <p>Տեսանիւթեր չկան։</p>
                    )
                )}

                {activeTab === "publications" && (
                    <>

                    {username == currentUser.username && <Newpost user={currentUser} updateParent={fetchPublications}/>}
                    {(publications.length > 0 ? (
                        publications.map(publication => (
                            <Post
                                user={currentUser}
                                post_uuid={publication.uuid}
                                key={publication.uuid}
                            />
                        ))
                    ) : (
                        <p>Յայտարարութիւններ չկան։</p>
                    ))}
                    </>
                )}

                {activeTab === "friends" && (
                    friends.length > 0 ? (
                        friends.map(friend => (
                            <UserInfo
                                user_uuid={friend.uuid}
                                key={friend.uuid}
                            />
                        ))
                    ) : (
                        <p>Ընկերներ չկան։</p>
                    )
                )}

                {activeTab === "groups" && (
                    groups.length > 0 ? (
                        groups.map(group => (
                            <GroupInfo
                                group={group}
                                key={group.uuid}
                            />
                        ))
                    ) : (
                        <p>Համատեղ խմբեր չկան։</p>
                    )
                )}

                {activeTab === "chats" && (
                    chats.length > 0 ? (
                        chats.map(chat => (
                            chat.members.includes(currentUser.uuid) &&
                            <ChatInfo
                                user={currentUser}
                                chat_uuid={chat.uuid}
                                key={chat.uuid}
                            />
                        ))
                    ) : (
                        <p>Համատեղ զրուցարաններ չկան։</p>
                    )
                )}

            </div>
        </div>
    );
}
