import { useState } from "react";
import axios from "axios";
import "./Newpost.css";
import API_URL from "../config/config";

export default function Newpost({ user, group=null, updateParent }) {
    const [showNewPost, setShowNewPost] = useState(false);
    const [text, setText] = useState("");
    const [mediaFiles, setMediaFiles] = useState([]);
    const [mediaPreviews, setMediaPreviews] = useState([]);

    const handleClick = (e) => {
        e.preventDefault();
        setShowNewPost(true);
    };

    const closePopup = () => {
        setShowNewPost(false);
        setText("");
        setMediaFiles([]);
        setMediaPreviews([]);
    };

    const handleTextChange = (e) => {
        setText(e.target.value);
    };

    const handleMediaChange = (e) => {
        const files = Array.from(e.target.files);
        const newFiles = files.map(file => ({
            file,
            preview: URL.createObjectURL(file),
            type: file.type.startsWith("image") ? "image" : "video",
        }));
        setMediaFiles(prev => [...prev, ...newFiles.map(f => f.file)]);
        setMediaPreviews(prev => [...prev, ...newFiles]);
    };

    const handlePost = async (e) => {
        e.preventDefault();
        const data = new FormData();
        data.append("content", text);
        mediaFiles.forEach((file) => data.append("files", file)); // Ensure "files" is the field name

        try {
            if (group != null) {
                data.append("group_uuid", group.uuid);
                await axios.post(`${API_URL}/api/groups/add_post_to_group`, data, {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
            }else {
                await axios.post(`${API_URL}/api/posts/`, data, {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
            }
            closePopup();
            updateParent();
        } catch (err) {
            console.error("Failed to create post", err);
        }
    };

    return (
        <article>
            <div className="user">
                <img src={`https://realschool.am/db/get?id=${user.photo}`} alt="User avatar" style={{
                    width: "50px",
                    height: "50px",
                    backgroundFit: "cover"
                }} />
                <div className="text-content">
                    <textarea
                        placeholder="Ի՞նչ եք մտածում"
                        onClick={handleClick}
                        readOnly
                    ></textarea>
                </div>
            </div>

            {showNewPost && (
                <div className="new-post-popup popup">
                    <form className="popup-content" onSubmit={handlePost}>
                        <div className="header">
                            <h3>Ստեղծել նոր յայտարարութիւն</h3>
                            <div className="close-button" onClick={closePopup}>X</div>
                        </div><hr />
                        <div className="user">
                            <img src={`https://realschool.am/db/get?id=${user.photo}`} alt="User avatar" />
                            <span><b>{user.name} {user.last_name}</b></span>
                        </div>
                        <div className="text-content">
                            <textarea
                                placeholder="Կիսուեք Ձեր մտքերով"
                                value={text}
                                onChange={handleTextChange}
                                autoFocus
                            ></textarea>
                        </div>

                        <div className="media-selection">
                            <span><b>Աւելացնել յայտարարութեանը</b></span>
                            <label className="file-label">
                                <div>
                                    <img src="/img/attach.png" />
                                    <img src="/img/tag.png" />
                                    <img src="/img/gif.png" />
                                    <img src="/img/emoji.png" />
                                    <img src="/img/location.png" />
                                </div>
                                <input
                                    type="file"
                                    accept="image/*, video/*"
                                    multiple
                                    onChange={handleMediaChange}
                                />
                            </label>
                        </div>

                        <div className="previews">
                            {mediaPreviews.map((media, index) => (
                                <div key={index} className="media-preview">
                                    {media.type === "image" ? (
                                        <img src={media.preview} alt={`Preview ${index + 1}`} />
                                    ) : (
                                        <video src={media.preview} controls />
                                    )}
                                </div>
                            ))}
                        </div>
                        <button className="post-btn" onClick={handlePost}><b>Ստեղծել յայտարարութիւն</b></button>
                    </form>
                </div>
            )}
        </article>
    );
}
