import { useState } from "react";
import axios from "axios"
import API_URL from "../config/config.js"

export default function UserSettings({ user }) {
    const [formData, setFormData] = useState({
        username: user.username || "",
        password: "", // Empty for security, should be set only if user wants to change it
        name: user?.name?.hye_AM ? user.name.hye_AM : user?.name ? user.name : "" || "",
        lastName: user.last_name || "",
        email: user.email || "",
        age: user.age || "",
        bio: user.bio || "",
        interests: user.interests || [],
        photo: `https://realschool.am/db/get?id=${user.photo}` || null, // Initialize with the current photo
        photoPreview: `https://realschool.am/db/get?id=${user.photo}` || null, // To display the current photo
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: name === "interests" ? value.split(",").map(item => item.trim()) : value,
        }));
    };

    const handlePhotoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData((prev) => ({
                ...prev,
                photo: file,
                photoPreview: URL.createObjectURL(file), // Preview the uploaded photo
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Prepare formData for backend, convert data to suitable format if necessary
        const submissionData = new FormData();
        submissionData.append("username", formData.username);
        submissionData.append("password", formData.password);
        submissionData.append("name", formData.name);
        submissionData.append("lastName", formData.lastName);
        submissionData.append("email", formData.email);
        submissionData.append("age", formData.age);
        submissionData.append("bio", formData.bio);
        submissionData.append("interests", JSON.stringify(formData.interests)); // Send interests as JSON string
        if (formData.photo) {
            submissionData.append("files", formData.photo); // Add photo file if selected
        }
        const res = await axios.post(`${API_URL}/api/users/`, submissionData, {
            withCredentials: true,
        }).then(() => {
            window.location.reload();
        });
        
        // setAllFriends(friendNames);
    };

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <h2 style={styles.header}>Աւգտատիրոջ կարգաւորումներ</h2>
                <form onSubmit={handleSubmit} style={styles.form}>
                    {formData.photoPreview && (
                        <div style={styles.photoContainer}>
                            <img src={`${formData.photoPreview}`} alt="Profile Preview" style={styles.photo} />
                        </div>
                    )}
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Փոխել էջի նկարը</label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handlePhotoChange}
                            style={styles.fileInput}
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Աւգտանուն</label>
                        <input
                            type="text"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            style={styles.input}
                            readOnly
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Գաղտնաբառ</label>
                        <input
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            style={styles.input}
                            placeholder="Մուտագրեք նոր գաղտնաբառը"
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Անուն</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            style={styles.input}
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Ազգանուն</label>
                        <input
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            style={styles.input}
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Եղ. հասցէ</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            style={styles.input}
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Տարիք</label>
                        <input
                            type="number"
                            name="age"
                            value={formData.age}
                            onChange={handleChange}
                            style={styles.input}
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Նկարագրութիւն</label>
                        <textarea
                            name="bio"
                            value={formData.bio}
                            onChange={handleChange}
                            style={styles.textarea}
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Հետաքրքրութիւններ (անջատել ստորակետով)</label>
                        <input
                            type="text"
                            name="interests"
                            // value={formData.interests.join(", ")}
                            onChange={handleChange}
                            style={styles.input}
                        />
                    </div>
                    <button type="submit" style={styles.button}>Պահպանել</button>
                </form>
            </div>
        </div>
    );
}

const styles = {
    container: {
        // display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
    },
    card: {
        backgroundColor: "#fff",
        padding: "30px",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        width: "100%",
    },
    header: {
        textAlign: "center",
        color: "#333",
        marginBottom: "20px",
        fontSize: "1.5rem",
    },
    form: {
        display: "flex",
        flexDirection: "column",
    },
    formGroup: {
        marginBottom: "15px",
    },
    label: {
        marginBottom: "5px",
        fontSize: "0.9rem",
        color: "#555",
    },
    input: {
        padding: "10px",
        border: "1px solid #ddd",
        borderRadius: "4px",
        fontSize: "1rem",
        width: "100%",
        boxSizing: "border-box",
    },
    fileInput: {
        border: "none",
        padding: "10px 0",
    },
    textarea: {
        padding: "10px",
        border: "1px solid #ddd",
        borderRadius: "4px",
        fontSize: "1rem",
        width: "100%",
        boxSizing: "border-box",
        minHeight: "80px",
        resize: "vertical",
    },
    button: {
        padding: "10px",
        borderRadius: "4px",
        border: "none",
        backgroundColor: "#4CAF50",
        color: "#fff",
        fontSize: "1rem",
        cursor: "pointer",
        transition: "background-color 0.3s",
    },
    photoContainer: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "15px",
    },
    photo: {
        width: "100px",
        height: "100px",
        borderRadius: "50%",
        objectFit: "cover",
        border: "2px solid #ddd",
    },
};
