import "../dashboard/Content.css"
import Post from "./Post"
import axios from "axios"
import React, { useEffect, useState } from 'react';
import API_URL from "../config/config";


export default function Posts({ user }) {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchPosts = async () => {
        try {
            const res = await axios.get(`${API_URL}/api/posts/`, {
                withCredentials: true,
            });
            console.log(res.data)
            setPosts(res.data.publications.reverse());
            setLoading(false);
        } catch (err) {
            setError(err);
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchPosts();
    }, []);

    // if (loading) return <h1>Բեռնում է...</h1>;
    // if (error) return <h1>Տեղի է ունեցել բեռնման սխալ</h1>;
    return (
        <div>
            {posts.map(post => (
                // <li key={post.uuid}>{post.content}</li>
                <Post post_uuid={post} user={user} updateParent={fetchPosts} />
            ))}
        </div>
    );
}
