import axios from "axios";
import { createContext, useEffect, useState } from "react";
import API_URL from "../pages/config/config";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );

  const fetchCurrentUser = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/auth/current_user`, {
        withCredentials: true,
      });
      // Assuming the response returns the user data correctly
      setCurrentUser(response.data);
    } catch (error) {
      console.error("Failed to fetch current user:", error);
      // Handle errors, e.g., reset current user or redirect
      setCurrentUser(null);
    }
  };

  const login = async (inputs) => {
    try {
      console.log(`${API_URL}/api/auth/login`)
    	const res = await axios.post(`${API_URL}/api/auth/login`, inputs, {
    	  withCredentials: true,
    	})
    	if (res.error) {
    	  setCurrentUser(null);
    	  return res;
    	}
      setCurrentUser(res.data)
    	return res;

    }catch (error) {
      console.log(error)
      return error.response.data
    }
  };

  const updateUser = (updatedUser) => {
    setCurrentUser((prevUser) => ({
      ...prevUser,
      ...updatedUser,
    }));
  };

  const logout = async () => {
    await axios.post(`${API_URL}/api/auth/logout`);
    setCurrentUser(null);
    window.location.href = "/login";
  };

  // Fetch current user data on mount
  useEffect(() => {
    if (currentUser != null) {
      fetchCurrentUser();
    }
  }, []);

  // Save current user data to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(currentUser));
  }, [currentUser]);

  return (
    <AuthContext.Provider value={{ currentUser, login, logout, updateUser }}>
      {children}
    </AuthContext.Provider>
  );
};
