import "../dashboard/Content.css"
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import API_URL from "../config/config";
import ChatInfo from "./ChatInfo";
import NewChat from "./Newchat";

const Chats = ({ user, create_new=true }) => {
    const [chats, setChats] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchChats = async () => {
        try {
            const res = await axios.get(`${API_URL}/api/chats/get_chats`, {
                withCredentials: true,
            });
            setChats(res.data.chats.reverse());
            setLoading(false);
        } catch (err) {
            setError(err);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchChats();
    }, []);

    if (loading) return <h1>Բեռնում է...</h1>;
    if (error) return <h1>Տեղի է ունեցել բեռնման սխալ</h1>;
    
    return (
        <div>
            {/* <NewChat updateChats={fetchChats} /> */}
            {create_new && <NewChat user={user} updateChats={fetchChats} />}
            {chats.map(chat => (
                <ChatInfo user={user} chat_uuid={chat} updateChats={fetchChats}/>
                // <h1>{chat.uuid} {user.username}</h1>
            ))}
        </div>
    );
};

export default Chats;
