import { useState, useEffect } from "react";
import axios from "axios";
import API_URL from "../config/config";

export default function AddUser({ user, addUsers }) {
    const [friendName, setFriendName] = useState("");
    const [allFriends, setAllFriends] = useState([]);
    const [filteredFriends, setFilteredFriends] = useState([]);
    const [selectedFriends, setSelectedFriends] = useState([]);

    // Fetch all friends once on mount
    useEffect(() => {
        const fetchFriends = async () => {
            try {
                const res = await axios.get(`${API_URL}/api/friends/`, {
                    withCredentials: true,
                });
                const friendNames = res.data.friends.map(friend => friend.username);
                setAllFriends(friendNames);
            } catch (error) {
                console.error("Failed to fetch friends", error);
            }
        };

        fetchFriends();
    }, []);

    // Handle input change and filter friends based on input
    const handleChange = (e) => {
        const query = e.target.value;
        setFriendName(query);

        // Filter friends based on search query
        if (query.trim() === "") {
            setFilteredFriends([]);
        } else {
            const results = allFriends.filter(friend =>
                friend.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredFriends(results);
        }
    };

    // Add selected friend to the selectedFriends list
    const addFriend = (friend) => {
        if (!selectedFriends.includes(friend)) {
            setSelectedFriends([...selectedFriends, friend]);
        }
        setFriendName(""); // Clear input
        setFilteredFriends([]); // Hide dropdown
    };

    const handleAddClick = (e) => {
        e.preventDefault();
        addUsers(selectedFriends);
        setSelectedFriends([]);
    }

    return (
        <article>
            <div className="user">
                <img src={`https://realschool.am/db/get?id=${user.photo}`} alt="User avatar" />
                <span style={{
                    color: "white",
                }}><b>{user.name} {user.last_name}</b></span>
            </div>
            <div className="text-content" style={{
                        margin: "0",
                    }}>
                <textarea
                    placeholder="Աւելացնել ընկեր"
                    value={friendName}
                    onChange={handleChange}
                    style={{
                        padding: "10px",
                        margin: "10px",
                        width: "97%"
                    }}
                ></textarea>
            </div>
            {/* Display filtered friends as suggestions */}
            {filteredFriends.length > 0 && (
                <div className="dropdown">
                    {filteredFriends.map((friend, index) => (
                        <button key={index} onClick={() => addFriend(friend)}>
                            {friend}
                        </button>
                    ))}
                </div>
            )}

            {/* Display selected friends */}
            <div className="selected-friends">
                {selectedFriends.map((friend, index) => (
                    <span key={index} className="friend-tag">
                        {friend} <button onClick={() => setSelectedFriends(selectedFriends.filter(f => f !== friend))}>&times;</button>
                    </span>
                ))}
            </div>
            <button onClick={handleAddClick} style={{
                    all: "unset",
                    width: "100%",
                    borderRadius: "10px",
                    backgroundColor: "rgb(251, 132, 48)",
                    padding: "4px"

                }}>Աւելացնել</button>
        </article>
    );
}
