import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";


import { AuthContext } from "../../context/authContext";

export default function Login({showLogin, onClick, next}) {

    const { login } = useContext(AuthContext);
    const [inputs, setInputs] = useState({
        username: '',
        password: ''
    });
    const navigate = useNavigate()
    const handleChange = (e) => {
        setInputs(prev => ({ ...prev, [e.target.name]: e.target.value }));
    };
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccessMessage("")
        setErrorMessage("");
        const res = await login(inputs)
        console.log(res)
        if (res.error) {
            setErrorMessage(res.error);
        }else {
            if (next) {
		navigate(next)
	    }else {
           	navigate("/feed")
      	    }
        }
    }
    return (
        <form className="form" id="login_form" display={showLogin ? "block" : "none"} onSubmit={handleSubmit}>
            <span id="welcom_prew">Մուտքագրեք ձեր աւգտանունը եւ գաղտնաբառը</span>
            <label>
                <span>Աւգտանուն</span>
                <input
                    type="text"
                    name="username"
                    onChange={handleChange}
                    required></input>
            </label>
            <label>
                <span>Գաղտնաբառ</span>
                <input
                    type="password"
                    onChange={handleChange}
                    name="password"
                    required></input>
            </label>
            {errorMessage && <span className="err_message">{errorMessage}</span>}
            {successMessage && <span className="success_message">{successMessage}</span>}
            <button type="submit" id="login">ՄՈՒՏՔ</button>
            <div>
                <span>Մոռացել եմ գաղտնաբառը</span>
                <span id="switch_to_register" active onClick={onClick}>Գրանցուել հիմա</span>
            </div>
        </form>

    )
} 
