import "../dashboard/Content.css"
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import NewGroup from './Newgroup';
import GroupInfo from './GroupInfo';
import API_URL from "../config/config";

const Groups = ({ user }) => {
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchGroups = async () => {
        try {
            const res = await axios.get(`${API_URL}/api/groups`, {
                withCredentials: true,
            });
            setGroups(res.data.groups.reverse());
            setLoading(false);
        } catch (err) {
            setError(err);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchGroups();
    }, []);

    if (loading) return <h1>Բեռնում է...</h1>;
    if (error) return <h1>Տեղի է ունեցել բեռնման սխալ</h1>;
    
    return (
        <div>
            <NewGroup updateGroups={fetchGroups} user={user} />
            {groups.map(group => (
                <GroupInfo updateGroups={fetchGroups} key={group.uuid} group_uuid={group.uuid} user={user} />
            ))}
        </div>
    );
};

export default Groups;
