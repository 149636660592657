import "../dashboard/Content.css"
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import API_URL from "../config/config";
import UserInfo from "../user/UserInfo";

const Friends = ({ user }) => {
    const [friends, setFriends] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchFriends= async () => {
        try {
            const res = await axios.get(`${API_URL}/api/friends`, {
                withCredentials: true,
            });
            setFriends(res.data.friends);
            setLoading(false);
        } catch (err) {
            setError(err);
            setLoading(false);
        }
    };
    
    const removeFriend = async (e) => {
        e.preventDefault();
        var user_uuid = e.currentTarget.getAttribute('data-user-uuid')
        try {
            await axios.post(`${API_URL}/api/friends/remove_friend`, {
                to : user_uuid,
            }, {
                withCredentials: true
            }).then(()=> {
                fetchFriends();
            });
        } catch (err) {
        }
    }

    useEffect(() => {
        fetchFriends();
    }, []);

    if (loading) return <h1>Բեռնում է...</h1>;
    if (error) return <h1>Տեղի է ունեցել բեռնման սխալ</h1>;
    
    return (
        <div style={{margin: "0 auto", width: "80%"}}>
            <h1>Ընկերներ</h1>
            {/* <NewChat updateChats={fetchChats} /> */}
            {friends.map(friend => (
                <UserInfo user_uuid={friend.uuid} deleteFrom={removeFriend}/>
                // <h1>{chat.uuid} {user.username}</h1>
            ))}
        </div>
    );
};

export default Friends;
