import axios from "axios";
import { useState, useEffect } from "react";
import "./Group.css"; // Ensure you have the appropriate styles
import { useParams } from 'react-router-dom';
import Post from "../posts/Post";
import Newpost from "../posts/Newpost";
import UserInfo from "../user/UserInfo";
import AddUser from "./AddUser";
import { useNavigate } from 'react-router-dom'
import API_URL from "../config/config";

export default function Group({user}) {
    const { uuid } = useParams();
    const [group, setGroup] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('publications'); // Default tab
    const navigate = useNavigate();
    const fetchGroup = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/groups/get`, {
                params: { group_uuid: uuid },
                withCredentials: true
            });
            setGroup(response.data.group);
            setLoading(false);
        } catch (err) {
            setError(err);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchGroup();
    }, [uuid]);

    const removeUser = async (e) => {
        var user_uuid = e.currentTarget.getAttribute('data-user-uuid')
        try {
            const response = await axios.post(`${API_URL}/api/groups/remove_user_from_group`, {
                group_uuid : uuid,
                user_uuid : user_uuid
            }, {
                withCredentials: true
            }).then(()=> {
                fetchGroup();
            });
        } catch (err) {
        }
    }

    const removePublication = async (e) => {
        var pub_uuid = e.currentTarget.getAttribute('data-pub-uuid')
        try {
            await axios.post(`${API_URL}/api/groups/remove_post_from_group`, {
                group_uuid : uuid,
                pub_uuid : pub_uuid
            }, {
                withCredentials: true
            }).then(()=> {
                fetchGroup();
            });
        } catch (err) {
        }
    }

    if (loading) return <h1 className="loading-text">Loading...</h1>;

    if (error) return <h1 className="error-text">Error: {error.message}</h1>;

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const addUsersToGroup = async (users) => {
        try {
            await axios.post(`${API_URL}/api/groups/add_users_to_group`, {
                group_uuid : uuid,
                users : users
            }, {
                withCredentials: true
            }).then(()=> {
                fetchGroup();
            });
        } catch (err) {
        }
    }

    const createChat = async () => {
        try {
            await axios.post(`${API_URL}/api/groups/create_chat_for_all_users`, {
                group_uuid : group.uuid
            }, {
                withCredentials: true
            }).then(()=> {
                fetchGroup();
            });
        } catch (err) {
        }
    }

    const handleNavigateToChat = (e) => {

        navigate(`/chat/${group.chat}`)
    }
 
    return (
        <div className="group-info-container">
            <div className="group-header">
                <img 
                    className="group-avatar" 
                    src={`https://realschool.am/db/get?id=${group.photo}`} 
                    alt="Group Avatar" 
                />
                <div className="group-details">
                    <h1 className="group-name">{group.name}</h1>
                    <p className="group-description">{group.description || "Նկարագրութիւն չկայ։"}</p>
                    {(!group.chat || group.chat == "") && (group.author == user.uuid) && 
                        (<button className="create-chat-btn" onClick={createChat}>Ստեղծել զրուցարան</button>)
                    }
                </div>
            </div>

            {/* Tabs for Members, Publications, and Admins */}
            <div className="tabs">
                <button 
                    className={`tab-button ${activeTab === 'publications' ? 'active' : ''}`} 
                    onClick={() => handleTabChange('publications')}
                >
                    Յայտարարութիւններ
                </button>
                <button 
                    className={`tab-button ${activeTab === 'members' ? 'active' : ''}`} 
                    onClick={() => handleTabChange('members')}
                >
                    Անդամներ
                </button>
                <button 
                    className={`tab-button ${activeTab === 'admins' ? 'active' : ''}`} 
                    onClick={() => handleTabChange('admins')}
                >
                    Գլխաւոր անդամներ
                </button>
                {group.chat && group.chat !== "" && (
                    <button 
                    className={`tab-button ${activeTab === 'admins' ? 'active' : ''}`} 
                    onClick={handleNavigateToChat}
                >
                    Զրուցարան
                </button>
                )}
            </div>

            <div className="tab-content">
                {activeTab === 'members' && (
                    <div className="members-section">
                        <h3 className="section-title">Ադնամներ</h3>
                        <ul className="members-list">
                        <AddUser user={user} addUsers={addUsersToGroup} />
                        {group.members.length > 0 ? (
                            group.members.map(member => (
                                <UserInfo 
                                    key={member}
                                    user_uuid={member} 
                                    deleteFrom={!group.admins.includes(member) ? removeUser : undefined} 
                                />
                            ))
                        ) : (
                            <li>Անդամներ չկան։</li>
                        )}
                        </ul>
                    </div>
                )}

                {activeTab === 'publications' && (
                    <div className="publications-section">
                        <h3 className="section-title">Յայտարարութիւններ</h3>
                        <ul className="publications-list">
                            <Newpost user={user} group={group} updateParent={fetchGroup} />
                            {group.publications.length > 0 ? (
                                group.publications.map((publication, index) => (
                                    <Post
                                        post_uuid={publication}
                                        user={user}
                                        group={group}
                                        updateParent={fetchGroup}
                                        deleteFrom={group.admins.includes(user.uuid) ? removePublication: undefined}
                                    />
                                ))
                            ) : (
                                <li>Յայտարարութիւններ չկան։</li>
                            )}
                        </ul>
                    </div>
                )}

                {activeTab === 'admins' && (
                    <div className="admins-section">
                        <h3 className="section-title">Գլխաւոր անդամներ</h3>
                        <ul className="admins-list">
                            {group.admins.length > 0 ? (
                                group.admins.map((admin, index) => (
                                    <UserInfo user_uuid={admin} />
                                ))
                            ) : (
                                <li>Գլխաւոր անդամներ չկան։</li>
                            )}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
}
