import { AuthContext } from "../../context/authContext";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";

export default function Logout() {
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const handleClick = async (e) => {
        e.preventDefault();
        await logout();
        setTimeout(() => {
            navigate("/login")
        }, 1000);
    }

    return (
        <a href="/logout" onClick={handleClick}>Ելք</a>
    )
} 