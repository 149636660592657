import axios from "axios";
import { useState, useCallback, useEffect } from "react";
import {useNavigate} from "react-router-dom"
import API_URL from "../config/config";
import Chats from "../chat/Chats"
import "./Post.css"
import ChatInfo from "../chat/ChatInfo";
import SendToPopup from "../chat/SendToPopup";

export default function Post({ post_uuid, user, updateParent, deleteFrom, group }) {
    const navigate = useNavigate();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [liked, setLiked] = useState(false);
    const [reactionCount, setReactionCount] = useState(0);
    const [commentsCount, setCommentsCount] = useState(0);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState("");
    const [replyTo, setReplyTo] = useState(null);
    const [showComments, setShowComments] = useState(false);


    const [showSendToChat, setShowSendToChat] = useState(false);

    const sendToChat = async (selectedChat) => {
        console.log(selectedChat)
        await axios.post(`${API_URL}/api/chats/send_message`, {
            chat_uuid: selectedChat,
            pub_uuid: post.uuid
        }, {
            withCredentials: true,
            headers: { "Content-Type": "multipart/form-data" },
        });
    }

    // Function to handle sending the publication to the selected chat
    const handleSendToChat = async (selectedChat, for_group=false) => {
        if (selectedChat) {
	    if (!for_group) {
                await sendToChat(selectedChat).then(() => {
                    alert("Ուղարկուած է։")
                });
                setShowSendToChat(false);
	    }
        } else {
            alert("Please select a chat.");
        }
    };

    const fetchPost = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/posts/get`, {
                params: { post_uuid, group_uuid: group ? group.uuid : undefined },
                withCredentials: true
            });
            const fetchedPost = response.data.publication;
            setPost(fetchedPost);
            setLiked(fetchedPost.reactions.includes(user.uuid));
            setReactionCount(fetchedPost.reactions.length);
            setCommentsCount(fetchedPost.comments.length);
            setComments(fetchedPost.comments || []);
            setLoading(false);
        } catch (err) {
            setError(err);
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchPost();
    }, [post_uuid, user.uuid]);

    const handleLike = useCallback(async () => {
        try {
            const updatedReactions = liked 
                ? post.reactions.filter(usr => usr !== user.uuid)
                : [...post.reactions, user.uuid];

            setLiked(!liked);
            setReactionCount(updatedReactions.length);

            const endpoint = liked 
                ? `${API_URL}/api/posts/unlike_post`
                : `${API_URL}/api/posts/like_post`;

            await axios.post(endpoint, { post_uuid: post.uuid }, { withCredentials: true });

            setPost(prevPost => ({ ...prevPost, reactions: updatedReactions }));
        } catch (err) {
            console.error(`Error ${liked ? "unliking" : "liking"} the post:`, err);
        }
    }, [liked, post, user.uuid]);

    const handleNewComment = async (e) => {
        e.preventDefault();
        if (!newComment) return;

        try {
            const response = await axios.post(`${API_URL}/api/posts/add_comment`, {
                post_uuid: post.uuid,
                content: newComment,
                reply_to: replyTo
            }, {
                withCredentials: true
            }).then(() => {
                fetchPost();
                setNewComment("");
                setReplyTo(null);
            });
        } catch (err) {
            console.error("Error adding comment:", err);
        }
    };
    const handleDeleteComment = async (e) => {
        try {
            const response = await axios.post(`${API_URL}/api/posts/delete_comment`, {
                post_uuid: post.uuid,
                comment_uuid: e,
            }, {
                withCredentials: true
            }).then(() => {
                fetchPost();
                setNewComment("");
                setReplyTo(null);
            });
        } catch (err) {
            console.error("Error adding comment:", err);
        }
    };

    const handleReply = (commentId) => {
        setReplyTo(commentId);
    };

    const handleClick = () => {
        navigate(`/post/${post.uuid}`)
    }

    const handleUserClick = () => {
        navigate(`/${post.author.username}`)
    }

	useEffect(() => {
		if (!post) return;
		console.log(post)
	}, [post])

    if (loading) return <h1 className="loading-text">Loading...</h1>;
    if (error) return <p>Error loading post.</p>;

    return (
        <article>
            <div onClick={handleUserClick}>
                <div className="user">
                    <img src={`https://realschool.am/db/get?id=${post.author.photo}`} alt="User avatar" />
                    <span style={{color: "white"}}>{post.author.name} {post.author.last_name}</span>
                </div>
            </div>
            <div onClick={handleClick}>
                <div className="text-content">
                    {post.content.slice(0, 500)}
                </div>
                {(post.photos.length > 0 || post.videos.length > 0) && (
                    <div className="image-content">
                        {post.photos.map((image, index) => (
                            <img key={index} src={`https://realschool.am/db/get?id=${image?.photo || image?.image}`} alt={`Image ${index}`} />
                        ))}
                        {post.videos.map((video, index) => (
                            <video key={index} controls src={`https://realschool.am/db/get?id=${video.video}`} />
                        ))}
                    </div>
                )}
            </div>
            <div className="buttons">
                <span onClick={handleLike}>
                    <img src={liked ? "/img/liked.svg" : "/img/like.svg"} alt="Like button" />
                    {reactionCount}
                </span>
                <span onClick={() => setShowComments(!showComments)}>
                    <img src="/img/comment.svg" alt="Comment button" />
                    {commentsCount}
                </span>
                <span onClick={() => setShowSendToChat(!showSendToChat)}>
                    <img src="/img/send.svg" alt="Send button" />
                </span>
                <span><em>{post.date_added}</em></span>
                <span><em>{post.views_count} դիտում</em></span>
            </div>
            {showSendToChat && (
                <SendToPopup
                    user={user} 
                    onClose={() => setShowSendToChat(false)} 
                    onSend={handleSendToChat} 
                />
            )}


            {deleteFrom && (
                <button data-pub-uuid={post.uuid} onClick={() => deleteFrom(post.uuid)}>Հեռացնել</button>
            )}

            {showComments && (<section className="comments-section">
                <h3>Մեկնաբանութիւններ</h3>
                <ul>
                    {comments.map((comment) => (
                        <li key={comment.id}>
                            <div className="comment">
                                {comment.content}
                                {comment.author == user.uuid &&
                                    <button onClick={() => handleDeleteComment(comment.uuid)}>Ջնջել</button>
                                }
                            </div>
                            {comment.replies && (
                                <ul className="replies">
                                    {comment.replies.map((reply) => (
                                        <li key={reply.id}>
                                            <div className="comment">
                                                <strong>{reply.author.username}</strong>: {reply.content}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
                <form onSubmit={handleNewComment}>
                    {replyTo && <p>Replying to comment {replyTo}</p>}
                    <textarea 
                        value={newComment} 
                        onChange={(e) => setNewComment(e.target.value)} 
                        placeholder="Գրէք մեկնաբանութիւն..." 
                        rows="3" 
                    />
                    <button type="submit">Հրապարակել մեկնաբանութիւնը</button>
                </form>
            </section>)}
        </article>
    );
}
