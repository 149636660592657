// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leftbar .menu {
    list-style: none;
    padding: 0;
}

.leftbar .menu li {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.leftbar .menu img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

/* Responsive Design */
@media (max-width: 1200px) {
    .leftbar {
        width: 10%;
    }
    .leftbar a {
        display: none; /* Hide text on mobile */
    }
    .leftbar .menu img {
        width: 100%;
        margin-right: 0; /* Adjust icon spacing */
    }
    .leftbar_user {
        display: none;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/LeftBar.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA,sBAAsB;AACtB;IACI;QACI,UAAU;IACd;IACA;QACI,aAAa,EAAE,wBAAwB;IAC3C;IACA;QACI,WAAW;QACX,eAAe,EAAE,wBAAwB;IAC7C;IACA;QACI,aAAa;IACjB;AACJ","sourcesContent":[".leftbar .menu {\n    list-style: none;\n    padding: 0;\n}\n\n.leftbar .menu li {\n    display: flex;\n    align-items: center;\n    margin: 10px 0;\n}\n\n.leftbar .menu img {\n    width: 24px;\n    height: 24px;\n    margin-right: 8px;\n}\n\n/* Responsive Design */\n@media (max-width: 1200px) {\n    .leftbar {\n        width: 10%;\n    }\n    .leftbar a {\n        display: none; /* Hide text on mobile */\n    }\n    .leftbar .menu img {\n        width: 100%;\n        margin-right: 0; /* Adjust icon spacing */\n    }\n    .leftbar_user {\n        display: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
