import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import UserInfo from "../user/UserInfo";
import "./Chat.css";
import Message from "./Message";
import API_URL,{HOST, PORT} from "../config/config";
import AddMember from "./AddMember";
import { useWebSocket } from "../../context/socketContext";



export default function Chat({ user, uuid = null }) {
    const params = useParams();
    const socket = useWebSocket();


    const chat_uuid = uuid || params.chat_uuid;

    const [chat, setChat] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [messages, setMessages] = useState({});
    const [newMessage, setNewMessage] = useState("");
    const [activeTab, setActiveTab] = useState("messages");
    const [files, setFiles] = useState([]);
    const messagesEndRef = useRef(null); // Ref for auto-scrolling

    const fetchChatData = async () => {
        try {
            const response = await axios.post(`${API_URL}/api/chats/get_chat`, {
                chat_uuid: chat_uuid
            }, {
                withCredentials: true,
            });
            if (!response.data.error) {
                setChat(response.data.chat);
                // const arr = Object.keys(response.data.chat.messages_test).map((key) => [key, response.data.chat.messages_test[key]]);

                // console.log(arr, "NAIRIIIIII")
                // setMessages(response.data.chat.messages)
                // TEST
                setMessages(response.data.chat.messages_test)
                setLoading(false);
            } else {
                setError({ message: "Զրուցարանը չի գտնուել" });
                setLoading(false);
            }
        } catch (err) {
            setError(err);
            setLoading(false);
        }
    };

    // const fetchAllMessages = async () => {
    //     try {
    //         const response = await axios.post(`${API_URL}/api/chats/get_messages`, {
    //             chat_uuid: chat_uuid
    //         }, {
    //             withCredentials: true,
    //         });
    //         if (response.data.messages && response.data.messages.length !== messages.length) {
    //             localStorage.setItem(chat_uuid, JSON.stringify(response.data.messages) )
    //             setMessages(response.data.messages);
    //             scrollToBottom(); // Scroll to bottom when new messages are fetched
    //         }
    //     } catch (err) {
    //         console.error("Failed to fetch messages", err);
    //     }
    // };

    useEffect(() => {
        if (!chat) {
            fetchChatData();
        }
        // if (chat) {
        // }
    }, [chat_uuid]);

    useEffect(() => {
        if (!chat) return
        if (socket) {
            socket.onmessage = (message) => {
                try {
                    if (!chat) return
                    const msg = JSON.parse(message.data);
                    if (msg.type == "new_message" || msg.type == "delete_message") {
                        if (msg.message && msg.message.chat_uuid == chat.uuid || msg.success) {
                            setMessages(msg.chat.messages_test)
                        }
                        console.log(msg)
                    }
                } catch(error) {
                    console.log(error)
                }
            }
        }
        return () => {
            if (socket) socket.onmessage = null; // Cleanup listener
        };
    }, [socket, chat])

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (!newMessage && files.length === 0) return;

        const formData = new FormData();
        formData.append("chat_uuid", chat_uuid);
        formData.append("message", newMessage);
        files.forEach((file) => formData.append("files", file));

        try {
            const response = await axios.post(`${API_URL}/api/chats/send_message`, formData, {
                withCredentials: true,
                headers: { "Content-Type": "multipart/form-data" },
            });
            setNewMessage("");
            setFiles([]);
            scrollToBottom();
        } catch (err) {
            console.error(err);
        }
    };

    const handleFileChange = (e) => {
        setFiles(Array.from(e.target.files));
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    if (loading) return <h1 className="loading-text">Loading...</h1>;
    if (error) return <h1 className="error-text">Error: {error.message}</h1>;

    return (
        <>
        {chat && (
            <>
                <div className="chat-container" style={{
                    // float: "left",
                    width: "100%"
                }}>
                    <div className="chat-header" style = {{
                        borderRadius: "2vh",
                        backgroundColor: "white",
                        color: "black",
                        boxShadow: "1px 1px 1px 0.1 black",
                        width: "100%",
                        margin: "10px auto",
                    }}>
                        <div className="user" style={{
                            marginLeft: "37%",
                        }}>
                            <img src={`https://realschool.am/db/get?id=${chat.photo}`} alt="User Avatar" style={{
                                width: "50px"
                            }} />
                            <h2>{chat.name.hye_AM ? chat.name.hye_AM : chat.name ? chat.name : "Անուն Չունի"}</h2>
                        </div>
                        {/* <p className="chat-description">{chat?.description || "Նկարագրութիւն չկայ։"}</p> */}
                    </div>


                    <div className="tab-content">
                        {activeTab === 'messages' && (
                            <>
                            <div className="messages-section">
                                <div className="messages-list">
                                    {Object.keys(messages).length > 0 ? (
                                        Object.values(messages).map((msg, index) => (
                                            <Message key={msg.id} message={msg} user={user} />
                                        ))
                                    ) : (
                                        <p>No messages to display</p>
                                    )}
                                    {/* {messages.length > 0 ? (
                                        messages.map((msg, index) => (
                                            // console.log(msg)
                                            <Message key={msg} uuid={msg} user={user} />
                                        ))
                                    ) : (
                                        console.log(messages)
                                        // <div>Նամակներ չկան</div>
                                    )} */}
                                    <div ref={messagesEndRef} /> {/* Scroll target */}
                                </div>
                            </div>
                            <div style={{
                                    position: "absolute",
                                    bottom: "5%",
                                    width: "100%",
                                }}>
                                <div className="file-preview">
                                    {files.map((file, index) => (
                                        <div key={index} className="file-preview-item">
                                            {file.type.startsWith("image") ? (
                                                <img src={URL.createObjectURL(file)} alt="Preview" className="file-preview-image" />
                                            ) : (
                                                <video controls className="file-preview-video">
                                                    <source src={URL.createObjectURL(file)} type={file.type} />
                                                    Ձեր զննարկիչը չի աջակցում տեսանիւթին:
                                                </video>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <form onSubmit={handleSendMessage} className="message-input-form">
                                    <label>
                                        <img src="/img/attach.png" style={{
                                            width: "42px",
                                            height: "100%",
                                            cursor: "pointer",
                                            marginLeft: "10px",
                                            marginRight: "5px",
                                            marginTop: "2px",
                                            borderRadius: "30%",
                                            background: "cover"
                                        }} />
                                        <input
                                            type="file" 
                                            multiple 
                                            accept="image/*,video/*" 
                                            onChange={handleFileChange} 
                                            className="file-input"
                                            style={{
                                                display: "none"
                                            }}
                                        />
                                    </label>
                                    <input 
                                        type="text" 
                                        value={newMessage} 
                                        onChange={(e) => setNewMessage(e.target.value)} 
                                        placeholder="Մուտքագրեք նամակը...." 
                                        required={!files.length} 
                                        style={{
                                            color: "black",
                                            backgroundColor: "#fff",
                                            borderRadius: "2vh"
                                        }}
                                    />
                                    <label>
                                        <img src="/img/send.png" style={{
                                            width: "42px",
                                            height: "100%",
                                            cursor: "pointer",
                                            marginRight: "10px",
                                            marginTop: "2px",
                                        }} />
                                        <input type="submit" value="Ուղարկել" style={{
                                            display: "none"
                                        }} />
                                    </label>
                                </form>
                            </div>
                            </>
                        )}
                    </div>
                </div>
            </>
        )}        
        </>
    );
}
