import "../dashboard/Content.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ChatInfo from "./ChatInfo";
import API_URL from "../config/config";
import "./Newchat.css"

export default function NewChat({ user, updateChats }) {
    const [showNewPost, setShowNewPost] = useState(false);
    const [inputs, setInputs] = useState({
        name: '',
        friends: [],
        private: false
    });
    const [allFriends, setAllFriends] = useState([]);
    useEffect(() => {
        const fetchFriends = async () => {
            try {
                const res = await axios.get(`${API_URL}/api/friends/`, {
                    withCredentials: true,
                });
                var friends = res.data.friends;
                var friend_names = [];
                for (const friend of friends) {
                    friend_names.push(friend.username)
                }
                console.log(friend_names)
                setAllFriends(friend_names);
            } catch (error) {
                console.error("Failed to fetch friends", error);
            }
        };

        fetchFriends();
    }, []);
    const [filteredFriends, setFilteredFriends] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();
        setShowNewPost(true);
    };

    const closePopup = () => {
        setShowNewPost(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs(prev => ({ ...prev, [name]: value }));

        if (name === "friendsSearch") {
            if (value.trim() === "") {
                setFilteredFriends([]);
                setShowDropdown(false);
                return;
            }
            const results = allFriends.filter(friend =>
                friend.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredFriends(results);
            setShowDropdown(results.length > 0);
        }
    };

    const addFriend = (friend) => {
        if (!inputs.friends.includes(friend)) {
            setInputs(prev => ({
                ...prev,
                friends: [...prev.friends, friend]
            }));
        }
        setFilteredFriends([]);
        setShowDropdown(false);
        setInputs(prev => ({ ...prev, ["friendsSearch"]: "" }));        
    };

    const removeFriend = (friend) => {
        setInputs(prev => ({
            ...prev,
            friends: prev.friends.filter(f => f !== friend)
        }));
    };

    const createChat = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${API_URL}/api/chats/create_chat`, inputs, {
                withCredentials: true,
            }).then(() => {
                updateChats();
                closePopup();
                setInputs(prev => ({
                    ...prev,
                    friends: []
                }));
            });
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div>
            <button className="add-chat-button" onClick={handleClick}>
                +
            </button>
            <article>
            {showNewPost && (
                <div className="new-chat-popup">
                    <div className="user">
                        <img src={`https://realschool.am/db/get?id=${user.photo}`} alt="User avatar" />
                        <span><b>{user.name} {user.last_name}</b></span>
                        <button className="close-btn" onClick={closePopup}>X</button>
                    </div>
                    <div className="chat-name">
                        <textarea
                            placeholder="Գրէք զրուցարանի անուանումը"
                            name="name"
                            onChange={handleChange}
                        ></textarea>
                    </div>
                    {/* <select name="private" onChange={handleChange}>
                        <option value={true}>Գաղտնի</option>
                        <option value={false}>Յայտնի</option>
                    </select> */}
                    <div className="selected-friends">
                        {inputs.friends.map((friend, index) => (
                            <span key={index} className="friend-tag">
                                <span>{friend}</span>
                                <button onClick={() => removeFriend(friend)}>&times;</button>
                            </span>
                        ))}
                    </div>
                    <div className="chat-name">
                        <textarea
                            placeholder="Ընտրեք ընկերների"
                            name="friendsSearch"
                            value={inputs["friendsSearch"]}
                            onChange={handleChange}
                        ></textarea>
                    </div>
                    {showDropdown && (
                        <div className="dropdown">
                            {filteredFriends.map((friend, index) => (
                                <button key={index} onClick={() => addFriend(friend)}>
                                    {friend}
                                </button>
                            ))}
                        </div>
                    )}
                    <button className="create-chat-btn" onClick={createChat}>Ստեղծել</button>
                </div>
            )}
            </article>
        </div>
    );
}
