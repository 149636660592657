// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.groupinfo {
    color : black;
    background-color: gray;
	cursor: pointer;
}

.groupinfo .user {
	height: 5vh;
	margin: 2%;
	display: flex;
	justify-content: start;
	align-items: center;
	font-size: 3vh;
}
.groupinfo .user img {
    width: 6%;
	height: auto;
	border-radius: 100%;
	/* margin-right: 5%; */
}
.groupinfo .text-content {
	margin: 0 2%;
	font-size: 2.25vh;
    width: 100%;
}
.groupinfo .image-content {
	height: 30vh;
	padding: 2%;
	overflow: hidden;
	overflow-x: auto;
	display: flex;
}
.groupinfo .image-content img, main article .image-content video{
	background: var(--system-border-color);
	height: 100%;
	border-radius:2vh;
	margin-right: 2%;
}
.groupinfo .buttons {
	height: 3vh;
	margin: 2%;
	display: flex;
	justify-content: start;
	align-items: center;
}
.groupinfo .buttons span {
	height: 80%;
	margin-right: 2%;
	cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/groups/GroupInfo.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;CACzB,eAAe;AAChB;;AAEA;CACC,WAAW;CACX,UAAU;CACV,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,cAAc;AACf;AACA;IACI,SAAS;CACZ,YAAY;CACZ,mBAAmB;CACnB,sBAAsB;AACvB;AACA;CACC,YAAY;CACZ,iBAAiB;IACd,WAAW;AACf;AACA;CACC,YAAY;CACZ,WAAW;CACX,gBAAgB;CAChB,gBAAgB;CAChB,aAAa;AACd;AACA;CACC,sCAAsC;CACtC,YAAY;CACZ,iBAAiB;CACjB,gBAAgB;AACjB;AACA;CACC,WAAW;CACX,UAAU;CACV,aAAa;CACb,sBAAsB;CACtB,mBAAmB;AACpB;AACA;CACC,WAAW;CACX,gBAAgB;CAChB,eAAe;AAChB","sourcesContent":[".groupinfo {\n    color : black;\n    background-color: gray;\n\tcursor: pointer;\n}\n\n.groupinfo .user {\n\theight: 5vh;\n\tmargin: 2%;\n\tdisplay: flex;\n\tjustify-content: start;\n\talign-items: center;\n\tfont-size: 3vh;\n}\n.groupinfo .user img {\n    width: 6%;\n\theight: auto;\n\tborder-radius: 100%;\n\t/* margin-right: 5%; */\n}\n.groupinfo .text-content {\n\tmargin: 0 2%;\n\tfont-size: 2.25vh;\n    width: 100%;\n}\n.groupinfo .image-content {\n\theight: 30vh;\n\tpadding: 2%;\n\toverflow: hidden;\n\toverflow-x: auto;\n\tdisplay: flex;\n}\n.groupinfo .image-content img, main article .image-content video{\n\tbackground: var(--system-border-color);\n\theight: 100%;\n\tborder-radius:2vh;\n\tmargin-right: 2%;\n}\n.groupinfo .buttons {\n\theight: 3vh;\n\tmargin: 2%;\n\tdisplay: flex;\n\tjustify-content: start;\n\talign-items: center;\n}\n.groupinfo .buttons span {\n\theight: 80%;\n\tmargin-right: 2%;\n\tcursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
