import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import "./GroupInfo.css"
import API_URL from "../config/config";
import "./UserInfo.css"

export default function UserInfo({ group, user_uuid, deleteFrom, clear, leftBar=false }) {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/users/find/${user_uuid}`, {
                    withCredentials: true
                });
                const fetchedUser = response.data.user;
                setUser(fetchedUser);
            } catch (err) {
                setError(err);
                console.error("Error fetching user:", err);
                // Optionally navigate or show an error page
                // navigate("/error"); // Uncomment if you want to navigate on error
            } finally {
                setLoading(false); // Ensure loading state is updated
            }
        };

        fetchUser();
    }, [user_uuid]);

    const handleRemoveClick = (e) => {
        deleteFrom(e);
    }

    const handleClick = (e) => {
        e.preventDefault();
        var username = e.currentTarget.getAttribute('data-user-username')
        if (clear) {
            clear()
        }
        navigate(`/${username}`)
    }

    if (loading) return <h1 className="loading-text">Loading...</h1>;
    if (error) return <h1 className="error-text">Error: {error.message}</h1>;

    return (
        <div className={`userinfo ${leftBar ? "leftbar_user" : ""} `}>
            <div className="user"  onClick={handleClick} data-user-username={user.username}>
                <img 
                    src={user.photo ? `https://realschool.am/db/get?id=${user.photo}` : 'default-avatar.png'} // Add a default avatar if photo is not available
                    alt="User Avatar" 
                />
                <span>{user.name} {user.last_name}</span>
            </div>
            {deleteFrom && (
                <button data-user-uuid={user.uuid} className="delete-button" onClick={handleRemoveClick}>X</button>
            )}
        </div>
    );
}
