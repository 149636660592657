// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userinfo {
    background: var(--system-background-color);
    border-radius: 2vh;
    margin: 2%;
    display: flex;
    flex-direction: column;
    color: black;
    background-color: gray;
    cursor: pointer;
    position: relative;
    border: 1px solid black;
}

.userinfo img {
    width: 6%;
    height: auto;
    border-radius: 100%;
}

.userinfo .delete-button {
	all: unset;
	text-align: center;
	border-radius: 1vh;
	width: 20px;
	height: auto;
	background-color: red;
	position: absolute;
	top: 25%;
	right: 5%;
	border: none;
	opacity: 0.5;
}


.userinfo .delete-button:hover {
	opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/pages/user/UserInfo.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,kBAAkB;IAClB,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,sBAAsB;IACtB,eAAe;IACf,kBAAkB;IAClB,uBAAuB;AAC3B;;AAEA;IACI,SAAS;IACT,YAAY;IACZ,mBAAmB;AACvB;;AAEA;CACC,UAAU;CACV,kBAAkB;CAClB,kBAAkB;CAClB,WAAW;CACX,YAAY;CACZ,qBAAqB;CACrB,kBAAkB;CAClB,QAAQ;CACR,SAAS;CACT,YAAY;CACZ,YAAY;AACb;;;AAGA;CACC,UAAU;AACX","sourcesContent":[".userinfo {\n    background: var(--system-background-color);\n    border-radius: 2vh;\n    margin: 2%;\n    display: flex;\n    flex-direction: column;\n    color: black;\n    background-color: gray;\n    cursor: pointer;\n    position: relative;\n    border: 1px solid black;\n}\n\n.userinfo img {\n    width: 6%;\n    height: auto;\n    border-radius: 100%;\n}\n\n.userinfo .delete-button {\n\tall: unset;\n\ttext-align: center;\n\tborder-radius: 1vh;\n\twidth: 20px;\n\theight: auto;\n\tbackground-color: red;\n\tposition: absolute;\n\ttop: 25%;\n\tright: 5%;\n\tborder: none;\n\topacity: 0.5;\n}\n\n\n.userinfo .delete-button:hover {\n\topacity: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
