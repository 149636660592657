// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search {
    position: relative;
}

.search-results {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    /* background-color: white; */
    border: 1px solid #ddd;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    list-style: none;
    padding: 0;
    margin: 0;
}

.search-result-item {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
}

.search-result-item img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.search-result-item:hover {
    background-color: #f0f0f0;
}

@media (max-width: 1200px) {
    h1 {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/Navbar.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,WAAW;IACX,6BAA6B;IAC7B,sBAAsB;IACtB,iBAAiB;IACjB,gBAAgB;IAChB,aAAa;IACb,gBAAgB;IAChB,UAAU;IACV,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,6BAA6B;AACjC;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".search {\n    position: relative;\n}\n\n.search-results {\n    position: absolute;\n    top: 100%;\n    left: 0;\n    width: 100%;\n    /* background-color: white; */\n    border: 1px solid #ddd;\n    max-height: 200px;\n    overflow-y: auto;\n    z-index: 1000;\n    list-style: none;\n    padding: 0;\n    margin: 0;\n}\n\n.search-result-item {\n    display: flex;\n    align-items: center;\n    padding: 8px;\n    cursor: pointer;\n    border-bottom: 1px solid #eee;\n}\n\n.search-result-item img {\n    width: 30px;\n    height: 30px;\n    border-radius: 50%;\n    margin-right: 10px;\n}\n\n.search-result-item:hover {\n    background-color: #f0f0f0;\n}\n\n@media (max-width: 1200px) {\n    h1 {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
