// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comments-section {
    margin-top: 20px;
    padding: 15px;
    background-color: gray;
    border-radius: 8px;
}

.comments-section h3 {
    margin-bottom: 10px;
    font-size: 1.2em;
    font-weight: bold;
}

.comments-section ul {
    list-style: none;
    padding: 0;
}

.comments-section .comment {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.comments-section .replies {
    padding-left: 20px;
    margin-top: 5px;
}

.comments-section .replies .comment {
    background-color: #eef;
    border-radius: 4px;
}

.comments-section textarea {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    margin-top: 10px;
}

.comments-section button {
    margin-top: 8px;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/pages/posts/Post.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,aAAa;IACb,6BAA6B;AACjC;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".comments-section {\n    margin-top: 20px;\n    padding: 15px;\n    background-color: gray;\n    border-radius: 8px;\n}\n\n.comments-section h3 {\n    margin-bottom: 10px;\n    font-size: 1.2em;\n    font-weight: bold;\n}\n\n.comments-section ul {\n    list-style: none;\n    padding: 0;\n}\n\n.comments-section .comment {\n    padding: 10px;\n    border-bottom: 1px solid #ddd;\n}\n\n.comments-section .replies {\n    padding-left: 20px;\n    margin-top: 5px;\n}\n\n.comments-section .replies .comment {\n    background-color: #eef;\n    border-radius: 4px;\n}\n\n.comments-section textarea {\n    width: 100%;\n    padding: 8px;\n    border-radius: 4px;\n    margin-top: 10px;\n}\n\n.comments-section button {\n    margin-top: 8px;\n    padding: 8px 16px;\n    background-color: #007bff;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
