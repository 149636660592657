import "../dashboard/Content.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ChatInfo from "./ChatInfo";
import API_URL from "../config/config";

export default function AddMember({ user, updateChat, chat_uuid, as_admin=false }) {
    const [showNewPost, setShowNewPost] = useState(false);
    const [inputs, setInputs] = useState({
        name: '',
        friends: [],
        private: false
    });
    const [allFriends, setAllFriends] = useState([]);
    useEffect(() => {
        const fetchFriends = async () => {
            try {
                var url = as_admin ? `${API_URL}/api/chats/get_members` : `${API_URL}/api/friends/`
                var res = undefined;
                if (as_admin) {
                    res = await axios.post(url, {
                        chat_uuid: chat_uuid
                    }, {
                        withCredentials: true,
                    });
                }else {
                    res = await axios.get(url, {
                        withCredentials: true,
                    });
                }
                var friends = as_admin ? res.data.members : res.data.friends;
                var friend_names = [];
                for (const friend of friends) {
                    friend_names.push(friend.username)
                }
                setAllFriends(friend_names);
            } catch (error) {
                console.error("Failed to fetch friends", error);
            }
        };

        fetchFriends();
    }, []);
    const [filteredFriends, setFilteredFriends] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();
        setShowNewPost(true);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs(prev => ({ ...prev, [name]: value }));

        if (name === "friendsSearch") {
            if (value.trim() === "") {
                setFilteredFriends([]);
                setShowDropdown(false);
                return;
            }
            const results = allFriends.filter(friend =>
                friend.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredFriends(results);
            setShowDropdown(results.length > 0);
        }
    };

    const addFriend = (friend) => {
        if (!inputs.friends.includes(friend)) {
            setInputs(prev => ({
                ...prev,
                friends: [...prev.friends, friend]
            }));
        }
        setFilteredFriends([]);
        setShowDropdown(false);
        setInputs(prev => ({ ...prev, ["friendsSearch"]: "" }));        
    };

    const removeFriend = (friend) => {
        setInputs(prev => ({
            ...prev,
            friends: prev.friends.filter(f => f !== friend)
        }));
    };

    const handleAddFriends = async (e) => {
        e.preventDefault();
        try {
            inputs.chat_uuid = chat_uuid;
            var url = as_admin ? `${API_URL}/api/chats/add_chat_admin` : `${API_URL}/api/chats/add_chat_member`;
            await axios.post(url, inputs, {
                withCredentials: true,
            }).then(() => {
                updateChat();
                setInputs(prev => ({
                    ...prev,
                    friends: []
                }));
            });
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <article>
            <div style={{width: "100%"}}>
                <input className="user btn btn-primary" name="friendsSearch" onChange={handleChange} placeholder="Գրէք ընկերոջ աւգտանունը..." style={{width: "100%", borderRadius: "1vh 1vh 0 0"}} />
                {showDropdown && (
                    <ul className="dropdown">
                        {filteredFriends.map((friend, index) => (
                            <li key={index} onClick={() => addFriend(friend)}>
                                {friend}
                            </li>
                        ))}
                    </ul>
                )}
                <div className="selected-friends">
                    {inputs.friends.map((friend, index) => (
                        <span key={index} className="friend-tag">
                            {friend}
                            <button onClick={() => removeFriend(friend)}>&times;</button>
                        </span>
                    ))}
                </div>
                <button onClick={handleAddFriends} style={{
                    all: "unset",
                    width: "97%",
                    borderRadius: "0 0 1vh 1vh",
                    backgroundColor: "rgb(251, 132, 48)",
                    padding: "4px"

                }}>Աւելացնել</button>
            </div>
        </article>
    );
}
