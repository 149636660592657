import { useState, useEffect } from "react";
import axios from "axios";
import "./Navbar.css"; // Add some basic styling
import UserInfo from "../user/UserInfo";
import API_URL from "../config/config";
import {useNavigate} from "react-router-dom"

export default function Navbar({ user }) {
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const navigate = useNavigate();

    // Fetch users based on the search term
    useEffect(() => {
        const fetchUsers = async () => {
            if (searchTerm.trim()) {
                try {
                        const response = await axios.get(`${API_URL}/api/users/search?query=${searchTerm}`, {
                                withCredentials: true,
                        });
                        console.log(response.data)
                        setSearchResults(response.data.users); // Assume response contains a `users` array
                } catch (error) {
                        console.error("Failed to fetch search results", error);
                }
            } else {
                setSearchResults([]); // Clear search results if input is empty
            }
        };

        fetchUsers();
    }, [searchTerm]);

    const handleClear = (e) => {
        setSearchTerm("")
    }

    const handleShowFriends = (e) => {
        navigate("/friends")
    }
    
    const handleShowChats = (e) => {
        navigate("/chats")
    }

    const handleClick = () => {
        navigate("/feed")
    }

    return (
        <header>
            <div className="logo" onClick={handleClick} style={{
                cursor: "pointer",
            }}>
                <img src="/img/mitaniq.svg" alt="Logo" />
                <h1>ՄիՏանիք</h1>
            </div>
            <div className="search">
                <input
                    type="search"
                    name="search"
                    placeholder="Որոնում..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                {/* Display search results below the input */}
                {searchResults.length > 0 && (
                    <ul className="search-results">
                        {searchResults.map((result) => (
                                <UserInfo user_uuid={result.uuid} clear={handleClear} />
                        ))}
                    </ul>
                )}
            </div>
            <div className="menu">
                {/* <img src="/img/save.svg" alt="Save" /> */}
                <img src="/img/friends.svg" alt="Friends" onClick={handleShowFriends} />
                <img src="/img/messager.svg" alt="Messenger" onClick={handleShowChats} />
                <img src="/img/notifications.svg" alt="Notifications" />
            </div>
        </header>
    );
}
