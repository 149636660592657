// Message.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Message.css"; // Ensure you have appropriate styles for Message
import API_URL from "../config/config";
import Post from "../posts/Post";

export default function Message({ message, user }) {
    // const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);

    // useEffect(() => {
    //     const fetchMessage = async () => {
    //         try {
    //             const response = await axios.post(`${API_URL}/api/chats/get_message`, {
    //                 message_uuid: uuid
    //             }, {
    //                 withCredentials: true,
    //             });
    //             setMessage(response.data.message);
    //         } catch (err) {
    //             setError("Could not load message.");
    //             console.error(err);
    //         }
    //     };

    //     fetchMessage();
    // }, [uuid]);

	const handleDelete = async (e) => {
		e.preventDefault();
		try {
			const resp = await axios.post(`${API_URL}/api/chats/delete_message`, {
				message_id: message.id,
				chat_uuid: message.chat_uuid
			}, {
				withCredentials: true,
			})
		}catch(err) {
			console.log(err)
		}

	}

    if (error) return <></>;
    if (!message) return <></>;
    const isCurrentUser = message.from.uuid === user.uuid;

    return (
	    <div className={`message-container ${isCurrentUser ? "right-align" : "left-align"}`}>
		    {!isCurrentUser && (
		        <img className="avatar" src={`https://realschool.am/db/get?id=${message.from.photo}`} alt={`${message.from.username}'s avatar`} />
		    )}
		    <div className="message-content-container">
		        <div className="message-header">
		            <span className="message-timestamp">
		                {new Date(message.date).toLocaleString()}
		            </span>
		            {!isCurrentUser && (
		                <span className="message-username">
		                    {message.from.username}
		                </span>
		            )}
		        </div>
		        <p className="message-content">{message.text}</p>
				{message.publication && (
					<Post post_uuid={message.publication} user={user} />
				)}
		
		        {/* Display Photos */}
		        {message.photos?.length > 0 && (
		            <div className="message-photos">
		                {message.photos.map(photo => (
		                    <img
		                        key={photo}
		                        src={`https://realschool.am/db/get?id=${photo}`}
		                        alt="Message attachment"
		                        className="message-photo"
		                    />
		                ))}
		            </div>
		        )}
		    </div>
			{isCurrentUser && (
				<button onClick={handleDelete}>X</button>
			)}
		</div>

    );
}
