// SendToChatPopup.js
import React, { useState } from 'react';
import ChatInfoInPopup from "./ChatInfoInPopup"
import GroupInfoInPopup from "../groups/GroupInfoInPopup"

const SendToChatPopup = ({ user, onClose, onSend }) => {
    const [selectedChat, setSelectedChat] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);

    const handleSend = (e) => {
        e.preventDefault();
        if (selectedChat) {
            onSend(selectedChat);
        }
        if (selectedGroup) {
            onSend(selectedGroup, true);
        }
    };

    return (
        <div className="popup">
            <form className="popup-content" style={styles.popup} onSubmit={handleSend}>
                <div style={styles.chatList}>
                    <h2 style={styles.header}>Select Chat to Send Publication</h2>
                    {user.chats.map(chat => (
                        <ChatInfoInPopup chat_uuid={chat} setSelectedChat={setSelectedChat} selectedChat={selectedChat} />
                    ))}
                    <h2 style={styles.header}>Select Group to Send Publication</h2>
                    {user.groups.map(group => (
                        <GroupInfoInPopup group_uuid={group} setSelectedGroup={setSelectedGroup} selectedGroup={selectedGroup} />
                    ))}
                </div>
                <button type="submit" style={styles.sendButton}>Ուղարկել</button>
                <button type="button" style={styles.closeButton} onClick={onClose}>Փակել</button>
            </form>
        </div>
    );
};

const styles = {
    popup: {
        width: '100%',
        padding: '20px',
        backgroundColor: '#f9f9f9',
        borderRadius: '12px',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
        textAlign: 'center',
    },
    header: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#333',
        marginBottom: '15px',
    },
    chatList: {
        height: '40vh',
        overflowY: 'auto',
        marginBottom: '20px',
        paddingRight: '10px',
    },
    chatOption: {
        padding: '10px',
        margin: '5px 0',
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        alignItems: 'center',
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        color: '#555',
    },
    radioInput: {
        marginRight: '10px',
        accentColor: '#4CAF50',
    },
    sendButton: {
        padding: '10px 20px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: '#4CAF50',
        border: 'none',
        borderRadius: '8px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        width: "45%",
    },
    closeButton: {
        width: "45%",
        padding: '10px 20px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: '#777',
        border: 'none',
        borderRadius: '8px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        marginTop: '10px'
    },
};

// Optional hover effect for the button
styles.sendButton[':hover'] = {
    backgroundColor: '#45a049',
};

export default SendToChatPopup;
