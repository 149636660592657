// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
    box-sizing: border-box;
}

.content {
    position: absolute;
    top: 10vh;
    left: var(--left-margin);
    right: var(--left-margin);
    width: calc(100% - var(--left-margin) - var(--right-margin));
    overflow: auto;
    /* justify-content: center;
    align-items: center; */
}

/* hr {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 1%;
} */`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/Content.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,wBAAwB;IACxB,yBAAyB;IACzB,4DAA4D;IAC5D,cAAc;IACd;0BACsB;AAC1B;;AAEA;;;;GAIG","sourcesContent":[".content {\n    box-sizing: border-box;\n}\n\n.content {\n    position: absolute;\n    top: 10vh;\n    left: var(--left-margin);\n    right: var(--left-margin);\n    width: calc(100% - var(--left-margin) - var(--right-margin));\n    overflow: auto;\n    /* justify-content: center;\n    align-items: center; */\n}\n\n/* hr {\n    width: 90%;\n    margin: 0 auto;\n    margin-bottom: 1%;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
