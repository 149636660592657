import Group from "../groups/Group";
import Newpost from "../posts/Newpost";
import Posts from "../posts/Posts";
import "./Content.css"
import { useParams } from 'react-router-dom';

export default function Content ({user,
                                PostsComponent,
                                GroupsComponent,
                                GroupComponent,
                                UserPageComponent,
                                ChatComponent,
                                ChatsComponent,
                                FriendsComponent,
                                PostComponent,
                                SettingsComponent}) {
    const { uuid } = useParams();
    return (
        <main style={{
            // width: (ChatComponent ? "50%" : "60%"),
            top: (ChatComponent ? "10vh" : "15vh"),
            height: ChatComponent ? "91%" : "",
            backgroundColor: ChatComponent ? '#f7f7fa' : ''
        }}>
            {PostsComponent && (
                <>
                    <Newpost user={user} />
                    <Posts user={user} />
                </>
            )}
            {GroupComponent && (
                <Group uuid={uuid} user={user} />
            )}
            {GroupsComponent && <GroupsComponent user={user} />}
            {UserPageComponent && <UserPageComponent user={user} />}
            {ChatComponent && <ChatComponent user={user} />}
            {ChatsComponent && <ChatsComponent user={user} />}
            {SettingsComponent && <SettingsComponent user={user} />}
            {FriendsComponent && <FriendsComponent user={user} />}
            {PostComponent && <PostComponent post_uuid={uuid} user={user} />}
        </main>
    );
}