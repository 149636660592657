import axios from "axios";
import { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom"
import "./GroupInfo.css"
import API_URL from "../config/config";

export default function GroupInfo({ updateGroups, group_uuid, user }) {
    const [group, setGroup] = useState(null)
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const fetchGroup = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/groups/get?group_uuid=${group_uuid}`, {
                withCredentials: true

            });
            const fetchedGroup = response.data.group;
            setGroup(fetchedGroup);
            setLoading(false);
        } catch (err) {
            console.log(err.response.data)
            setError(err.response.data);
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchGroup()
    }, [group_uuid]);

    if (loading) return <h1 className="loading-text">Loading...</h1>;
    // if (error) return <p>Error loading chat. {error} </p>;
    if (!group) return <></>;

    const handleClick = (e) => {
        e.preventDefault();
        navigate(`/groups/${group.uuid}`)
    }

    const deleteGroup = async () => {
        await axios.post(`${API_URL}/api/groups/delete`, {
            group_uuid: group.uuid
        }, {
            withCredentials: true
        }).then(()=>{
            updateGroups();
        })
    } 

    const handleDelete = async () => {
        const apply = prompt("Հաստատ՞, գրեք այո։");
        if (apply == "այո") {
            try {
                await deleteGroup();
                alert("Խումբը հաջողությամբ հեռացվեց։");
            } catch (error) {
                console.error("Error deleting group:", error);
                alert("Խմբի հեռացման ժամանակ սխալ տեղի ունեցավ։");
            }
        } else {
            console.log("User canceled the deletion.");
        }
    };
    
    return (
        <>
        <div className="chatinfo">
            <div onClick={handleClick}>
                <div className="user">
                    <img 
                        src={`https://realschool.am/db/get?id=${group.photo}`}
                        alt="Group Avatar" 
                    />
                    <div className="text-content">
                        {group?.name?.hye_AM && group.name.hye_AM}
                        {!group?.name?.hye_AM && group.name}
                    </div>
                </div>
            </div>
            {group.admins.includes(user.uuid) &&
                <button className="delete-button" onClick={handleDelete}>X</button>
            }
        </div>
        </>
    );
}
