import React, { useState, useEffect } from 'react';
import axios from "axios";
import API_URL from "../config/config";


const GroupInfoInPopup = ({ group_uuid, setSelectedGroup, selectedGroup }) => {
    const [group, setGroup] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const fetchGroupData = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/groups/get?group_uuid=${group_uuid}`, {
                withCredentials: true,
            });
            console.log(response.data)
            if (!response.data.error) {
                setGroup(response.data.group);
                setLoading(false);
            } else {
                setError({ message: "Զրուցարանը չի գտնուել" });
                setLoading(false);
            }
        } catch (err) {
            setError(err);
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchGroupData();
    }, [group_uuid]);

    if (loading) return <h1 className="loading-text">Loading...</h1>;
    if (error) return <h1 className="error-text">Error: {error.message}</h1>;
    return (
        <div key={group.uuid} style={styles.groupOption}>
            <img src={`https://realschool.am/db/get?id=${group.photo}`} style={styles.img} />
            <label style={styles.label}>
                <input 
                    type="radio" 
                    name="chat" 
                    value={group?.name} 
                    checked={selectedGroup === group.uuid}
                    onChange={() => setSelectedGroup(group.uuid)} 
                    style={styles.radioInput}
                />
                <span>Group Name: {group?.name}</span>
            </label>
        </div>
    );
};

const styles = {
    popup: {
        width: '100%',
        padding: '20px',
        backgroundColor: '#f9f9f9',
        borderRadius: '12px',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
        textAlign: 'center',
    },
    header: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#333',
        marginBottom: '15px',
    },
    groupList: {
        height: '40vh',
        overflowY: 'auto',
        marginBottom: '20px',
        paddingRight: '10px',
    },
    groupOption: {
        padding: '10px',
        margin: '5px 0',
        backgroundColor: '#fff',
        // borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        alignItems: 'center',
    },
    label: {
        width: "100%",
        // display: 'flex',
        // alignItems: 'center',
        float : "left",
        fontSize: '14px',
        color: '#555',
    },
    radioInput: {
        marginRight: '10px',
        accentColor: '#4CAF50',
    },
    sendButton: {
        padding: '10px 20px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: '#4CAF50',
        border: 'none',
        borderRadius: '8px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        width: "45%",
    },
    closeButton: {
        width: "45%",
        padding: '10px 20px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: '#777',
        border: 'none',
        borderRadius: '8px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        marginTop: '10px'
    },
    img: {
        width: "5%",
    }
};

// Optional hover effect for the button
styles.sendButton[':hover'] = {
    backgroundColor: '#45a049',
};

export default GroupInfoInPopup;
