import { useState } from "react";
import axios from 'axios';
import API_URL from "../config/config";

export default function Register({showLogin, onClick}) {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== repeatPassword) {
            setErrorMessage("Գաղտնաբառերը չեն համընկնում");
            return;
        }
        setSuccessMessage("")
        setErrorMessage("");
        const userData = {
            username,
            email,
            password,
        };
        axios.post(`${API_URL}/api/auth/register`, userData)
            .then(response => {
                if (response.data.success) {
                    setSuccessMessage(response.data.success)
                    setTimeout(()=> {
                        onClick();
                    }, 2000)
                }else {
                    setErrorMessage(response.data.error)
                }
            })
            .catch(error => {
                setErrorMessage(error.response.data.error)
            });
    }

    return (
        <form className="form" id="register_form" display={showLogin ? "none" : "block"} onSubmit={handleSubmit}>
            <span id="welcom_prew">Մուտքագրեք ձեր աւգտանունը եւ գաղտնաբառը</span>
            <label>
                <span>Աւգտանուն</span>
                <input
                    type="text"
                    name="username"
                    id="reg_username"
                    onChange={(e) => {setUsername(e.target.value)}}
                    required></input>
            </label>
            <label>
                <span>Եղ. հասցե</span>
                <input
                    type="email"
                    name="email"
                    id="reg_email"
                    onChange={(e) => {setEmail(e.target.value)}}
                    required></input>
            </label>
            <label>
                <span>Ստեղծեք գաղտնաբառ</span>
                <input
                    type="password"
                    name="password"
                    id="reg_password"
                    onChange={(e) => {setPassword(e.target.value)}}
                    required></input>
            </label>
            <label>
                <span>Կրկնեք գաղտնաբառը</span>
                <input
                    type="password"
                    name="repeat_password"
                    id="repeat_password"
                    onChange={(e) => {setRepeatPassword(e.target.value)}}
                    required></input>
            </label>
            {errorMessage && <span className="err_message">{errorMessage}</span>}
            {successMessage && <span className="success_message">{successMessage}</span>}
            <button type="submit" id="register">ԳՐԱՆՑՈՒԵԼ</button>
            <div>
                <span>Մոռացել եմ գաղտնաբառը</span>
                <span id="switch_to_login" active onClick={onClick}>Մուտք գործել</span>
            </div>
        </form>
    )
} 