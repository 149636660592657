import { useParams } from "react-router-dom";
import Chats from "../chat/Chats";
import { useEffect, useState } from "react";
import axios from "axios";
import API_URL from "../config/config";
import UserInfo from "../user/UserInfo";
import AddMember from "../chat/AddMember";

export default function MessengerRightBar({ user, ChatComponent}) {
    const {chat_uuid} = useParams(); 
    const [chat, setChat] = useState(null)
    const [activeTab, setActiveTab] = useState("members");

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const fetchChatData = async () => {
        try {
            const response = await axios.post(`${API_URL}/api/chats/get_chat`, {
                chat_uuid: chat_uuid
            }, {
                withCredentials: true,
            });
            console.log(response)
            setChat(response.data.chat);
        } catch (err) {
        }
    };

    const removeUser = async (e) => {
        var user_uuid = e.currentTarget.getAttribute('data-user-uuid')
        try {
            await axios.post(`${API_URL}/api/chats/remove_chat_member`, {
                chat_uuid : chat_uuid,
                user_uuid : user_uuid
            }, {
                withCredentials: true
            }).then(()=> {
                fetchChatData();
            });
        } catch (err) {
        }
    }

    const removeAdmin = async (e) => {
        var user_uuid = e.currentTarget.getAttribute('data-user-uuid')
        try {
            await axios.post(`${API_URL}/api/chats/remove_chat_admin`, {
                chat_uuid : chat_uuid,
                user_uuid : user_uuid
            }, {
                withCredentials: true
            }).then(()=> {
                fetchChatData();
            });
        } catch (err) {
        }
    }

    useEffect(() => {
        fetchChatData();
    }, [chat_uuid])
    return (
        <footer>
            {chat && (
                <>
                <div className="tabs">
                    <button className={`tab-button ${activeTab === 'members' ? 'active' : ''}`} onClick={() => handleTabChange('members')}>Անդամներ</button>
                    <button className={`tab-button ${activeTab === 'admins' ? 'active' : ''}`} onClick={() => handleTabChange('admins')}>Գլխաւոր անդամներ</button>
                </div>
                {activeTab === 'members' && (
                <div className="members-section" style={{float: "right"}}>
                    <h3>Անդամներ</h3>
                    <ul className="members-list">
                        <AddMember chat_uuid={chat.uuid} updateChat={fetchChatData} as_admin={false} />
                        {chat?.members && chat.members.length > 0 ? (
                            chat.members.map((member) => (
                                <UserInfo
                                    key={member}
                                    user_uuid={member}
                                    user={user}
                                    deleteFrom={chat.admins.includes(user.uuid) && member != user.uuid ? removeUser : undefined} />
                            ))
                        ) : (
                            <li>Անդամներ չկան</li>
                        )}
                    </ul>
                </div>
                )}
                {activeTab === 'admins' && (
                    <div className="admins-section">
                        <h3>Գլխաւոր անդամներ</h3>
                        <ul className="admins-list">
                            <AddMember chat_uuid={chat.uuid} updateChat={fetchChatData} as_admin={true} />
                            {chat?.admins && chat.admins.length > 0 ? (
                                chat.admins.map((admin) => (
                                    <UserInfo
                                        key={admin}
                                        user_uuid={admin}
                                        deleteFrom={chat.author == user.uuid && admin != user.uuid ? removeAdmin : undefined}/>
                                ))
                            ) : (
                                <li>Գլխաւոր անդամներ չկան</li>
                            )}
                        </ul>
                    </div>
                )}
            </>
            )}
        </footer>
    )
}