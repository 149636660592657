import './Auth.css';
import { useContext, useState } from "react"
import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
    Navigate,
    useLocation,
} from "react-router-dom";
import { AuthContext } from './context/authContext';
import { WebSocketProvider } from './context/socketContext'
// import { useWebSocket } from './context/socketContext'
import Auth from "./pages/auth/Auth.js"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Navbar from './pages/dashboard/Navbar.js';
import Leftbar from './pages/dashboard/Leftbar.js';
import Rightbar from './pages/dashboard/Rightbar.js';
import MessengerLeftBar from './pages/dashboard/MessengerLeftBar.js';
import MessengerRightBar from './pages/dashboard/MessengerRightBar.js';
import Content from './pages/dashboard/Content.js';
import Posts from './pages/posts/Posts.js';
import Groups from './pages/groups/Groups.js';
import Group from './pages/groups/Group.js';
import UserPage from './pages/user/UserPage.js';
import Chat from './pages/chat/Chat.js';
import Chats from './pages/chat/Chats.js';
import UserSettings from './pages/settings/UserSettings.js';
import Friends from './pages/friends/Friends.js';
import Post from './pages/posts/Post.js';

function App() {
    const { currentUser } = useContext(AuthContext);

    // const socket = useWebSocket();
    const ProtectedRoute = ({ children }) => {
      const location = useLocation(); // Access the current URL path
      const currentPath = location.pathname; // Get the pathname
      console.log("Current Path:", currentPath);
      if (!currentUser) {
          return <Navigate to={`/login?next=${encodeURIComponent(currentPath)}`} />;

      }
      return children;
    };
    const queryClient = new QueryClient();
    const Layout = ({ContentProps}) => {
      return (
        <QueryClientProvider client={queryClient}>
          <ProtectedRoute>
            <WebSocketProvider user={currentUser}>
              <div className='main-content'>
                  <Navbar user={currentUser} />
                  {ContentProps && ContentProps.ChatComponent ? (
                    <>
                    <MessengerRightBar user={currentUser} {...ContentProps} />
                    <Content user={currentUser} {...ContentProps} />
                    <MessengerLeftBar user={currentUser} {...ContentProps} />
                    </>
                  ) : (
                    <>
                    <Content user={currentUser} {...ContentProps} />
                    <Leftbar user={currentUser} {...ContentProps} />
                    <Rightbar user={currentUser} {...ContentProps} groups={ContentProps && !ContentProps.GroupsComponent} chats={ContentProps && !ContentProps.ChatsComponent} />
                    </>
                  )}
              </div>
            </WebSocketProvider>
          </ProtectedRoute>
        </QueryClientProvider>
      );
    };
  
    const router = createBrowserRouter([
      {
        path: "/",
        element: <Layout
        ContentProps={{
          PostsComponent: Posts,
        }} />
      },
      {
        path: "/login",
        element: <Auth />,
      },
      {
        path: "/groups",
        element: <Layout
          ContentProps={{
            GroupsComponent: Groups,
          }} />,
      },
      {
        path: "/groups/:uuid",
        element: <Layout
          ContentProps={{
            GroupComponent: Group,
          }}
        />
      },
      {
        path: "/feed",
        element: <Layout
        ContentProps={{
          PostsComponent: Posts,
        }} />
      },
      {
        path: "/post/:uuid",
        element: <Layout
        ContentProps={{
          PostComponent: Post,
        }} />
      },
      {
        path: "/:username",
        element: <Layout
        ContentProps={{
          UserPageComponent: UserPage,
        }} />
      },
      {
        path: "/chat/:chat_uuid",
        element: <Layout
        ContentProps={{
          ChatComponent: Chat,
        }} />
      },
      {
        path: "/chats",
        element: <Layout
        ContentProps={{
          ChatsComponent: Chats,
        }} />
      },
      {
        path: "/settings",
        element: <Layout
        ContentProps={{
          SettingsComponent: UserSettings,
        }} />
      },
      {
        path: "/friends",
        element: <Layout
        ContentProps={{
          FriendsComponent: Friends,
        }} />
      },
    ]);
  
    return (
      <div>
        <RouterProvider router={router} />
      </div>
    );
  }
  
  export default App;
