// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatinfo {
	background-color: #1a1919;
    color: white;
    cursor: pointer;
    /* background: var(--system-background-color); */
    border-radius: 2vh;
    margin: 2%;
    display: flex;
    flex-direction: column;
	position: relative;
}

.chatinfo .user {
	height: 5vh;
	margin: 2%;
	display: flex;
	justify-content: start;
	align-items: center;
	font-size: 3vh;
}

.chatinfo .user img {
    width: 6%;
	height: auto;
	border-radius: 100%;
	/* margin-right: 5%; */
}

.chatinfo .text-content {
	margin: 0 2%;
	font-size: 2.25vh;
    width: 100%;
}

.grochatinfoupinfo .image-content {
	height: 30vh;
	padding: 2%;
	overflow: hidden;
	overflow-x: auto;
	display: flex;
}

.chatinfo .image-content img, main article .image-content video{
	background: var(--system-border-color);
	height: 100%;
	border-radius:2vh;
	margin-right: 2%;
}

.chatinfo .delete-button {
	all: unset;
	text-align: center;
	border-radius: 1vh;
	width: 20px;
	height: auto;
	background-color: red;
	position: absolute;
	top: 30%;
	right: 5%;
	border: none;
	opacity: 0.3;
}


.chatinfo .delete-button:hover {
	opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/pages/chat/ChatInfo.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;IACtB,YAAY;IACZ,eAAe;IACf,gDAAgD;IAChD,kBAAkB;IAClB,UAAU;IACV,aAAa;IACb,sBAAsB;CACzB,kBAAkB;AACnB;;AAEA;CACC,WAAW;CACX,UAAU;CACV,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,cAAc;AACf;;AAEA;IACI,SAAS;CACZ,YAAY;CACZ,mBAAmB;CACnB,sBAAsB;AACvB;;AAEA;CACC,YAAY;CACZ,iBAAiB;IACd,WAAW;AACf;;AAEA;CACC,YAAY;CACZ,WAAW;CACX,gBAAgB;CAChB,gBAAgB;CAChB,aAAa;AACd;;AAEA;CACC,sCAAsC;CACtC,YAAY;CACZ,iBAAiB;CACjB,gBAAgB;AACjB;;AAEA;CACC,UAAU;CACV,kBAAkB;CAClB,kBAAkB;CAClB,WAAW;CACX,YAAY;CACZ,qBAAqB;CACrB,kBAAkB;CAClB,QAAQ;CACR,SAAS;CACT,YAAY;CACZ,YAAY;AACb;;;AAGA;CACC,UAAU;AACX","sourcesContent":[".chatinfo {\n\tbackground-color: #1a1919;\n    color: white;\n    cursor: pointer;\n    /* background: var(--system-background-color); */\n    border-radius: 2vh;\n    margin: 2%;\n    display: flex;\n    flex-direction: column;\n\tposition: relative;\n}\n\n.chatinfo .user {\n\theight: 5vh;\n\tmargin: 2%;\n\tdisplay: flex;\n\tjustify-content: start;\n\talign-items: center;\n\tfont-size: 3vh;\n}\n\n.chatinfo .user img {\n    width: 6%;\n\theight: auto;\n\tborder-radius: 100%;\n\t/* margin-right: 5%; */\n}\n\n.chatinfo .text-content {\n\tmargin: 0 2%;\n\tfont-size: 2.25vh;\n    width: 100%;\n}\n\n.grochatinfoupinfo .image-content {\n\theight: 30vh;\n\tpadding: 2%;\n\toverflow: hidden;\n\toverflow-x: auto;\n\tdisplay: flex;\n}\n\n.chatinfo .image-content img, main article .image-content video{\n\tbackground: var(--system-border-color);\n\theight: 100%;\n\tborder-radius:2vh;\n\tmargin-right: 2%;\n}\n\n.chatinfo .delete-button {\n\tall: unset;\n\ttext-align: center;\n\tborder-radius: 1vh;\n\twidth: 20px;\n\theight: auto;\n\tbackground-color: red;\n\tposition: absolute;\n\ttop: 30%;\n\tright: 5%;\n\tborder: none;\n\topacity: 0.3;\n}\n\n\n.chatinfo .delete-button:hover {\n\topacity: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
