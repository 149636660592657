// import './Auth.css';
import {useState} from "react"
import Login from "./Login"
import Register from "./Register"
import { useLocation, useNavigate } from "react-router-dom";

function Auth() {
  const [showLogin, setShowLogin] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  // Extract the 'next' query parameter
  const params = new URLSearchParams(location.search);
  const nextPath = params.get("next") || "/"; // Default to home if 'next' is not provided
  console.log(nextPath)


  return (
    <div className="Auth">
      <div className="welcom">
        <div className="prew">
            <img src={`${process.env.PUBLIC_URL}/img/mitaniq.svg`} alt="Mitaniq logo" />
            <h1>Մուտք ՄիՏանիք 2</h1>
        </div>
        {showLogin ? (
          <Login onClick={() => setShowLogin(false)} next={nextPath} />
        ) : (
          <Register onClick={() => setShowLogin(true)} />
        )}
      </div>
    </div>
  );
}

export default Auth;
