import Logout from "../auth/Logout";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../../context/authContext";
import UserInfo from "../user/UserInfo.js";
import { useContext } from "react";
import './LeftBar.css'; // Import CSS file

export default function LeftBar({ user }) {
    const navigate = useNavigate();
    const handleShowPosts = (e) => {
        e.preventDefault();
        navigate("/feed");
    };
    const handleShowGroups = (e) => {
        e.preventDefault();
        navigate("/groups");
    };
    const handleShowSettings = (e) => {
        e.preventDefault();
        navigate("/settings");
    };
    const handleShowChats = (e) => {
        e.preventDefault();
        navigate("/chats");
    };

    const { logout } = useContext(AuthContext);
    const handleLogout = async (e) => {
        e.preventDefault();
        await logout();
        setTimeout(() => {
            navigate("/login")
        }, 1000);
    }

    return (
        <nav className="leftbar">
            <ul className="menu">
                <UserInfo user_uuid={user.uuid} leftBar={true} />
                <li>
                <span onClick={handleShowPosts}>
                    <img src="/img/home.svg" alt="Home Icon" />
                    <a>Գլխաւոր</a>
                </span>
                </li>
                <li>
                    <span onClick={handleShowChats}>
                        <img src="/img/messager.svg" alt="Messenger Icon" />
                        <a>Զրուցարաններ</a>
                    </span>
                </li>
                <li>
                    <span onClick={handleShowGroups}>
                        <img src="/img/events.svg" alt="Events Icon" />
                        <a>Խմբեր</a>
                    </span>
                </li>
                <li>
                    <span onClick={handleShowSettings}>
                        <img src="/img/settings.svg" alt="Settings Icon" />
                        <a>Կարգաւորումնէր</a>
                    </span>
                </li>
                <li>
                    <span onClick={handleLogout}>
                        <img src="/img/logout.png" alt="Logout Icon" />
                        <a>Ելք</a>
                    </span>
                </li>
            </ul>
        </nav>
    );
}
