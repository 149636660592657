import axios from "axios";
import { useState, useEffect } from "react";
import {useNavigate, useParams} from "react-router-dom"
import "./ChatInfo.css"
import API_URL from "../config/config";

export default function ChatInfo({ user, chat_uuid, updateChats }) {
    const navigate = useNavigate();
    const [chat, setChat] = useState(null)
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const params = useParams()
    const chatUUID = params.chat_uuid;

    const fetchChat = async () => {
        try {
            const response = await axios.post(`${API_URL}/api/chats/get_chat`, {
                chat_uuid: chat_uuid
            }, {
                withCredentials: true
            });
            const fetchedChat = response.data.chat;
            console.log(response.data.error)
            setChat(fetchedChat);
            setLoading(false);
        } catch (err) {
            setError(err);
            setLoading(false);
        }
    }
    useEffect(() => {
        if (!chat) {
            fetchChat()
            console.log(chat, "UHFEUHFUEHU")
        };
    }, [chat_uuid]);

    useEffect(() => {
        console.log(chat);
    }, [chat]);

    const handleClick = (e) => {
        e.preventDefault();
        navigate(`/chat/${chat.uuid}`)
    }

    const deleteChat = async () => {
        await axios.post(`${API_URL}/api/chats/delete_chat`, {
            chat_uuid: chat.uuid
        }, {
            withCredentials: true
        }).then(()=>{
            if (updateChats) updateChats();
        })
    }

    const handleDelete = async () => {
        const apply = prompt("Հաստատ՞, գրեք այո։");
        if (apply == "այո") {
            try {
                await deleteChat();
                alert("Զրուցարանը հաջողությամբ հեռացվեց։");
            } catch (error) {
                console.error("Error deleting group:", error);
                alert("Զրուցարանի հեռացման ժամանակ սխալ տեղի ունեցավ։");
            }
        } else {
            console.log("User canceled the deletion.");
        }
    };

    if (loading) return <h1 className="loading-text">Loading...</h1>;
    if (error) return <p>Error loading chat. {error} </p>;
    if (!chat) return <></>;
    
    return (
        <>
        <div className="chatinfo" style={{
            backgroundColor: (chat_uuid == chatUUID ? "#fb8430" : "#1a1919")
        }}>
            <div onClick={handleClick}>
                <div className="user">
                    <img 
                        src={`https://realschool.am/db/get?id=${chat.photo}`}
                        alt="Group Avatar" 
                    />
                    <div className="text-content">
                        {chat?.name?.hye_AM && chat.name.hye_AM}
                        {!chat?.name?.hye_AM && chat.name}
                    </div>
                </div>
            </div>
            {chat.admins.includes(user.uuid) &&
                <button className="delete-button" onClick={handleDelete}>X</button>
            }
        </div>
        </>
    );
}
