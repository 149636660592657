import React, { useEffect, useState } from 'react';
import axios from 'axios';
import API_URL from "../config/config";
import ChatInfo from "../chat/ChatInfo";
import GroupInfo from '../groups/GroupInfo';

export default function RightBar({user, chats, groups}) {
    return (
      <footer>
        {chats && (
          <>
          <h3>Նամակագրութիւններ</h3>
            <ul>
              {user?.chats && user.chats.length > 0 && (
                user.chats.slice(0, 5).map(chat => (
                  <ChatInfo key={chat} user={user} chat_uuid={chat} />
                ))
              )}
            </ul>
          </>
        )}
        {groups && (
          <>
            <h3>Խմբեր</h3>
            <ul>
              {user?.groups && user.groups.length > 0 && (
                user.groups.slice(0, 5).map(group => (
                  <GroupInfo key={group} user={user} group_uuid={group} />
                ))
              )}
            </ul>
          </>
        )}
      </footer>
    );
  }
  